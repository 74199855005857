.Hero_titelcont {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Hero_titelSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 1rem;
  position: relative;
  margin: 2rem;
}
.Hero_trofySection {
  width: 100%;
  display: flex;
  margin: 1rem 2rem;
}
.Herosection {
  width: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 4rem;
  position: relative;
}
.Hero_titelSection h3 {
  font-family: Montserrat;
  width: 80%;
  font-size: 60px;
  font-weight: 400;
  line-height: 73.39px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f1faff;
}
.Hero_titelSection p {
  margin-top: 0px;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 400;
  line-height: 10.69px;
  text-align: left;
  color: #fff;
}

.Hero_titelSection span {
  color: #c7eb40;
  font-weight: 600;
}
.Hero_trofySection img {
  width: 22rem;
  position: absolute;
  top: 13rem;
  right: 9rem;
}
.herocta_cont {
  position: relative;
  display: flex; /* Keep flex for centering if needed */
  width: 60%; /* Ensure this is applied */
  height: 60px;
  align-self: center; /* Align within a flex container */
  justify-content: flex-start; /* Center-align the button horizontally */
  cursor: pointer;
}
.herocta_cont1 {
  width: 100%; /* Ensure this is applied */
}

.herocta_cont button {
  width: 400px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background: linear-gradient(0deg, #c3ea31, #c3ea31),
    linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #033038;
  cursor: pointer;
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: relative;
  z-index: 0; /* Ensure it sits above the pseudo-element */
  border: none; /* Remove default border */
}

.herocta_cont::before {
  content: "";
  position: absolute;
  width: 400px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 40px;
  padding: 2px; /* Border thickness */
  background: linear-gradient(165.4deg, #ffffff -10.07%, rgba(255, 255, 255, 0) 89.61%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: 1; /* Place behind the button */
}

.Hero_achivement {
  position: relative;
  display: flex;
  padding: 63.67px 60.13px;
  gap: 15.47px;
  border-radius: 40px;
  background: linear-gradient(0deg, #0e8785, #0e8785),
    linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(4px);
  margin: 4rem 3rem;
}
.Hero_achivement::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 40px; /* Match the border radius of the main element */
  padding: 4px; /* The thickness of the border */
  background: linear-gradient(165.4deg, #ffffff -10.07%, rgba(255, 255, 255, 0) 89.61%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: -1; /* Place behind the main element */
}
.Achivement_section {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
  gap: 1.5rem;
}
.Achivement_title {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.Achivement_title h3 {
  font-family: Montserrat;
  font-size: 34px;
  font-weight: 700;
  line-height: 47.75px;
  letter-spacing: -0.025em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
}
.Achivement_title p {
  font-family: Montserrat;
  font-size: 14.33px;
  font-weight: 400;
  line-height: 22.28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
}

.Achivement_title span {
  color: #caff00;
  font-weight: 800;
}
.Achivement_section1 {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-around;
}
.Achivement_cont {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
}
.Achivement_cont h6 {
  font-family: Montserrat;
  font-size: 28.65px;
  font-weight: 700;
  line-height: 35.81px;
  letter-spacing: -0.025em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
}
.Achivement_cont p {
  font-family: Montserrat;
  font-size: 12.73px;
  font-weight: 400;
  line-height: 10.1px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
}
.Achivement_cont img {
  margin-top: auto;
  width: 33px;
}
.whySection {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.whyTitel {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 3rem 0rem;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  line-height: 47.75px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #072a60;
}
.whyTitel span {
  font-weight: 600;
  color: #97ba0f;
  margin-left: 6px;
}
.whydetailSection {
  width: 100%;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 10rem;
}
.whyImgCont {
  width: 70%;
  z-index: 1;
  display: flex;
  justify-content: center;
}
.whyImgCont img {
  width: 450px;
  border-radius: 15px;
}
.glow-box {
  position: absolute;
  top: 30px;
  width: 100px;
  height: 100px;
  background-color: #f7ffb6;
  border-radius: 20px;
  box-shadow: 0 0 39px 32px rgba(247, 255, 182, 3.4), 0 0 100px 60px rgba(247, 255, 182, 0.2);
}
.glow-box1 {
  position: absolute;
  top: 30px;
  width: 1px;
  height: 1px;
  background-color: #f7ffb6;
  border-radius: 20px;
  box-shadow: 0 0 39px 32px rgba(247, 255, 182, 3.4), 0 0 100px 60px rgba(247, 255, 182, 0.2);
}

.illutionLine {
  position: absolute;
  z-index: 2;
  top: 330px;
}
.box1 {
  top: 9rem;
  left: 8rem;
}
.box2 {
  top: 21rem;
  left: 29rem;
}
.whydetilsList {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.whydetilsList_element {
  width: 100%;
  display: flex;
  gap: 2rem;
}
.whydetilsList_img {
  width: 65px;
  height: 60px;
}

.whydetilsList_text {
  width: 70%;
  gap: 1px;
}
.whydetilsList_text h3 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #072a60;
}
.whydetilsList_text p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #696984;
}
.EvetnInfoSection {
  background-size: cover;
  width: 100%;
  display: flex;
}
.EvetnInfocont {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 3rem;
}
.GradeContainers {
  width: 90%;
  display: flex;
  gap: 1rem;
}
.gradeCont {
  width: 33%;
  padding: 1rem 3.2rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: #ffffffde;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
}

.gradeCont h6 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #204349;
}
.gradeCont p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #204349;
  margin-bottom: 0px;
}
.Eligibility {
  width: fit-content;
  height: Hug (28px) px;
  display: flex;
  justify-content: center;
  padding: 4px 6px;
  text-align: center;
  gap: 10px;
  border-radius: 8px;
  opacity: 0px;
  background-color: #d2ef62;
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.MultiFormat {
  width: 90%;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  columns: #204349;
}

.MultiFormat span {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.EvetnInfo_title {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  line-height: 47.75px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062f37;
}
.EvetnInfo_heading {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062f37;
}
.EvetnInfo_Des {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062f37;
}
.TEventStructure {
  width: 40%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.6rem;
  align-items: center;
  padding: 4rem 3rem;
}
.TEventStructure h4 {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  line-height: 47.75px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
}
.TEventStructure img {
  margin: 1rem 0rem;
  width: 100%;
}
/* timeline */
.timeline-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  position: relative;
  padding: 2rem;
  background-color: #002b36; /* Dark background */
  border-radius: 8px;
  color: #ffffff; /* White text */
  max-width: 400px;
  margin: auto;
}

.timeline-step {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.step-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #d8ff00; /* Yellow-green color */
  color: #002b36; /* Dark text */
  font-weight: bold;
  border-radius: 50%;
  font-size: 1rem;
  z-index: 1;
}

.step-content h3 {
  margin: 0;
  font-size: 1rem;
  color: #d8ff00; /* Yellow-green color */
}

.step-content p {
  margin: 0;
  font-size: 0.9rem;
  color: #ffffff;
}

.timeline-connector {
  position: relative;
  width: 2px;
  height: 50px;
  background-color: #d8ff00; /* Yellow-green color */
  margin-left: 14px; /* Align with the step number */
}

.timeline-connector:after {
  content: "";
  position: absolute;
  top: 50%;
  left: -10px;
  width: 20px;
  height: 2px;
  background-color: #d8ff00; /* Yellow-green color */
}

/* F&Q */

.faq-section {
  max-width: 1000px;
  margin: 2rem auto;
  padding: 2rem;
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.faq-section h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  color: #333;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-item {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 1rem;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.faq-item:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.faq-question {
  display: flex;
  justify-content: space-between;
  font-family: Montserrat;
  font-size: 14.39px;
  font-weight: 500;
  line-height: 17.54px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.faq-answer {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #555;
  line-height: 1.4;
}

.faq-icon {
  font-size: 1rem;
  color: #888;
}

.faq-footer {
  margin-top: 2rem;
  text-align: center;
  font-size: 0.9rem;
  color: #666;
}

.faq-footer a {
  color: #007bff;
  text-decoration: none;
}

.faq-footer a:hover {
  text-decoration: underline;
}
.box11 {
  top: 162px;
  right: -107px;
}
.box22 {
  top: -130px;
  left: -63px;
}
.routimgcont {
  width: 100%;
  display: flex;
  justify-content: center;
}
.helpSection {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #000;
}
.helpcont {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #000;
}

.helpNumber {
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
}

.helpNumber:hover {
  text-decoration: underline;
}
@media only screen and (min-width: 280px) and (max-width: 780px) {
  .Herosection {
    padding: 1rem 1rem;
  }
  .Hero_achivement {
    flex-direction: column;
    width: 98%;
    margin: 1rem 0rem;
    padding: 20px 15px;
  }
  .Hero_titelSection {
    margin: 0px;
  }
  .Hero_trofySection {
    flex-direction: column;
    align-items: center;
    margin: 0px;
    gap: 1rem;
  }
  .Hero_titelSection h3 {
    font-size: 32px;
    font-weight: 400;
    line-height: 40.29px;
    text-align: left;
    width: 100%;
  }
  .Hero_titelSection p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 10.07px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .Hero_trofySection img {
    order: 1;
    position: relative;
    width: 16rem;
    top: 0;
    right: 0;
  }
  .herocta_cont::before {
    width: 100%;
  }
  .herocta_cont {
    order: 2;
    width: 90%;
  }
  .Achivement_title h3 {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.025em;
    text-align: center;
  }
  .Achivement_title {
    width: 100%;
  }
  .Achivement_title p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .whyTitel {
    flex-direction: column;
    margin: 1rem 0rem;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .Achivement_cont {
    gap: 8px;
  }
  .whydetailSection {
    flex-direction: column;
    gap: 0.5rem;
    margin: 1rem 0rem;
    padding: 10px 20px;
  }
  .whyImgCont img {
    width: 100%;
  }
  .whyImgCont {
    width: 100%;
  }
  .illutionLine {
    display: none;
  }
  .glow-box {
    width: 1px;
    height: 1px;
  }
  .box1 {
    top: 9rem;
    left: 3rem;
  }
  .box2 {
    top: 20rem;
    left: 20rem;
  }
  .whydetilsList {
    width: 100%;
    margin: 1rem 0rem;
  }
  .whydetilsList_text h3 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 0px;
  }
  .whydetilsList_text p {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .EvetnInfoSection {
    flex-direction: column;
    gap: 1rem;
    margin: 0rem 0rem;
    padding-top: 1rem;
  }
  .EvetnInfocont {
    width: 100%;
  }
  .EvetnInfocont {
    padding: 0.5rem 1rem;
  }
  .GradeContainers {
    width: 100%;
    flex-direction: column;
  }
  .gradeCont {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 0rem;
  }

  .EvetnInfo_title {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 0px;
  }
  .EvetnInfo_heading {
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 0px;
  }
  .EvetnInfo_Des {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 0px;
  }
  .Eligibility {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .gradeCont h6 {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 0px;
  }
  .gradeCont p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .box11 {
    top: 73px;
    right: 5px;
  }
  .box22 {
    top: 1px;
    left: 1px;
  }
  .TEventStructure {
    width: 100%;
    padding: 19px 0px;
  }
  .TEventStructure h4 {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 47.75px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .routimgcont img {
    width: 90%;
    margin-top: 0rem;
    margin-bottom: 1rem;
  }
  .Achivement_cont img {
    margin-top: auto;
    width: 22px;
  }
  .Achivement_cont h6 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.98px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 0px;
  }
  .Achivement_cont p {
    font-family: Montserrat;
    font-size: 9.5px;
    font-weight: 400;
    line-height: 13.32px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .Achivement_section {
    width: 100%;
    padding: 0rem 0.3rem;
  }
  .MultiFormat {
    width: 100%;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .MultiFormat span {
    font-weight: 700;
  }
  .faq-section {
    margin: 0px;
  }
  .examNavbar {
    display: flex;
    width: 100%;
    padding: 10px;
    align-items: center;
    gap: 24px;
    background: var(--White, #fff);
    justify-content: space-between;
    box-shadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.05);
  }
  .examNavLogo {
    width: 101.03px;
    height: 56.92px;
  }
  .helpSection img {
    width: 34px;
    height: 34px;
    cursor: pointer;
  }
  .helpcont span {
    font-family: Montserrat;
    font-size: 8px;
    font-weight: 400;
    line-height: 10.73px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .helpNumber {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 12.27px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .faq-section h2 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.38px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .faq-question {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
