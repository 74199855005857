.student__onboarding__widget {
  padding: 20px;
  display: flex;
  gap: 5%;
  justify-content: center;
}
.onboarding--heading {
  font-weight: 600;
  font-size: 1.25rem;
  margin: 0;
  color: #edcd6a;
}
.onboarding--title {
  font-weight: 500;
  font-size: 14px;
  color: #f2f2f2;
  margin: 0;
  line-height: 1.4;
  letter-spacing: 0.5px;
}
.onboarding-grad--btn {
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  border-radius: 30px;
  letter-spacing: 0.08em;
  color: #3c3306;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  padding: 17px 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 0;
  width: 100%;
  cursor: pointer;
  min-width: 125px;
}
.onboarding-grad--btn:hover {
  color: #3c3306;
}
.student__inner__widget {
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.max--330 {
  max-width: 330px;
}
.student__onboarding__progress {
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.onboarding__progress__bar__combo {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 15px 1fr;
  align-items: center;
  margin-top: 1.5rem;
}
.onboarding__progress__bar__line {
  background-color: rgba(237, 205, 106, 0.25);
  height: 16px;
  border-radius: 15px;
  position: relative;
}
.bar__line__bar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 16px;
  background: #f6d670;
  transition: width 1.5s;
}
.aspirebox {
  display: inline-flex;
  align-items: center;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  color: #edcd6a;
  padding: 10px;
  vertical-align: bottom;
  justify-content: flex-start;
  text-align: start;
  background: url("../images/talkbox.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
}
.aspirebox svg {
  max-width: 100%;
  position: absolute;
  inset: 0;
  top: 50%;
  transform: translateY(-50%);
}
.bg--mobileset {
  background: linear-gradient(107.08deg, #031441 -1.63%, #020a1f 106.25%);
  border-top: 0.5px solid #2a3853;
  border-bottom: 0.5px solid #202020;
  padding: 2rem 0rem;
}
.onboarding__item__lists {
  display: grid;
  grid-gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.onboarding__list__item {
  box-shadow: 0px 18px 21px rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(23px);
  border-radius: 8px;
  background: #1b3966;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  padding: 0.75rem 1rem;
  justify-content: center;
  position: relative;
  border-bottom: 4px solid #274d85;
  cursor: pointer;
}
.on_litem_name {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  display: block;
}
.onboarding__list__item svg {
  max-width: 46px;
  height: auto;
}
.layerblur--circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(39, 175, 223, 0.4);
  filter: blur(22.5px);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  margin: auto;
}
.onboarding__list__item.active {
  background: #275aa6;
  border-bottom-color: #6996d8;
}

.onboarding__list__item:hover {
  background: #275aa6;
  border-bottom-color: #6996d8;
}

.bubble--loader {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 0 2px;
  background: #f7d670;
}
.curve--loaderpoint {
  position: absolute;
  left: 0;
  bottom: 0;
}
.bg--mobileset--item {
  text-align: start;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 160px 1fr;
  align-items: center;
}
.maxw--560 {
  max-width: 560px;
}
.aspirebox + .aspirebox {
  margin-top: 1rem;
}
.maxw--700 {
  max-width: 700px;
}
.importmodal.newalert .modal-content {
  background: #141c2b;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 4%);
  margin-bottom: -8px;
}
.importmodal.newalert .alert-m-heading {
  color: #edcd6a;
}
.importmodal.newalert .alert-m-text {
  color: #fff;
}
.importmodal.newalert .pink--fill {
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  border: 0;
  color: var(--color-dark);
  padding: 0.85rem 1.5rem;
}
.importmodal.newalert .pink--fill:hover {
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
}
.importmodal.newalert .borderBtn {
  background: radial-gradient(160% 160% at 50.4% 58%, rgba(255, 249, 215, 0.213) 0%, rgba(255, 255, 255, 0.111) 100%);
  background: radial-gradient(#31394b, #31394b) padding-box,
    linear-gradient(to right, #b6a238, #eddc82, #a89637) border-box;
  border: 1px solid transparent;
  padding: 0.6rem 1.5rem;
}
.importmodal.newalert .borderBtn span {
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  font-size: 15px;
}
.newalert .d-btn-two {
  grid-gap: 1rem;
}
.importmodal.alertmodal.newalert .modal-dialog {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  align-items: flex-end;
}
svg.xs-closeicon {
  position: absolute;
  right: 30px;
}
.student__onboarding__contentmenu {
  width: 100%;
}
.student__onboarding__innerbox {
  width: 100%;
  /*max-width: 1060px;*/
  margin-top: 1.5rem;
  padding-bottom: 50px;
}
.board__top--heading {
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  width: max-content;
  text-align: start;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  cursor: pointer;
  line-height: 1.3;
}

.border__goback {
  background-color: azure;
  color: whites;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  width: max-content;
  text-align: start;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.video-container {
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
  justify-content: center;
}
.video-card {
  width: calc(33.44% - 20px);
}
.video-card.full-width {
  width: 100%;
  margin: 0;
}
.thumbnail {
  position: relative;
  cursor: pointer;
}

.thumbnail img {
  width: 100%;
  height: 100%;
}
.thumbnail.active {
  border: 2px solid grey;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 3rem;
  opacity: 0.8;
}

div > h4 {
  color: #f2f2f2;
  font-size: large;
  font-weight: 600;
}

.heading__viewAll {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.video-title {
  position: absolute;
  top: 0px;
  left: 10px;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
  z-index: 1;
}

.video-description {
  position: relative;
  bottom: 21px;
  font-size: 12px;
  left: 10px;
  color: white;
  z-index: 1;
}

.upcoming__box--item {
  background: radial-gradient(160% 160% at 50.4% 58%, rgba(255, 249, 215, 0.1136) 0%, rgba(255, 255, 255, 0.0592) 100%);
  box-shadow: 0px 14px 33px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 12px;
  border: 1px solid #eddc82;
}
.live--badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 5px 7px;
  color: #fff;
}
.upcoming__box--title {
  font-size: 1rem;
  font-weight: 500;
  color: #f2f2f2;
  margin: 0;
  line-height: 1.3;
}
.upcoming__box--time {
  font-size: 0.875rem;
  font-weight: 400;
  color: rgba(242, 242, 242, 0.9);
  margin: 0;
  line-height: 1.3;
}
.view__detailbar {
  border-top: 0.5px solid rgba(255, 255, 255, 0.2);
  border-radius: 0px;
  padding: 8px 0px 0px 0px;
  text-align: right;
  margin-top: 1.5rem;
}
.view__detailbar--link {
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  border: 0;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  align-items: center;
}
.today--badge {
  background: #98820e !important;
  padding: 5px 10px;
}
.board__upcoming__box {
  // padding-top: 0rem;
  // padding-left: 18rem;
  padding: 1px 110px 0 110px;
}
.overflow__x__custom {
  overflow-x: auto;
  flex-wrap: nowrap;
}
.min-mob-300 {
  min-width: 300px;
}
.student__onboarding__asidemenu ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.student__onboarding__asidemenu ul li a {
  padding: 0.85rem 1.5rem;
  text-decoration: none;
  display: inline-grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  width: 100%;
  font-weight: 500;
  color: rgba(186, 186, 186, 1);
  font-size: 0.875rem;
}
.student__onboarding__asidemenu ul li:has(a.active) {
  color: #fff;
  font-weight: 700;
  border-left: 3px solid #f4d442;
  background: linear-gradient(90deg, rgba(255, 222, 0, 0.4) 0%, rgba(252, 228, 68, 0) 100%);
}
.student__onboarding__asidemenu ul li a .activeIcon,
.student__onboarding__asidemenu ul li.active .normalIcon {
  display: none;
}
.student__onboarding__asidemenu ul li.active .activeIcon {
  display: block;
}
.onboarding__help {
  background: #223461;
  padding: 20px;
  margin-top: auto;
}
.onboarding__help p {
  letter-spacing: 0.2px;
  color: #fff;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 400;
}
.onboarding__help a {
  font-weight: 600;
  font-size: 0.875rem;
  text-decoration: none;
  color: #fff;
}
.onboarding__help a svg {
  margin-right: 5px;
}
.board__calendar__box {
  padding: 0px 15px;
  margin-top: 2rem;
}
.board__calendar__box .custom__calendar__full {
  background: rgba(9, 46, 138, 0.15);
  box-shadow: 0px -7px 60px 1px rgb(0 0 0 / 25%);
  border: 0;
}
.board__calendar__box .fc-theme-standard td,
.board__calendar__box .fc-theme-standard th {
  border-color: #4e67a84d !important;
}
.board__calendar__box .fc-theme-standard .fc-scrollgrid {
  border-color: #4e67a84d !important;
}
.board__calendar__box .custom__calendar__full .fc .fc-col-header-cell-cushion {
  color: rgba(178, 146, 44, 1);
}
.board__calendar__box .fc-daygrid-body .fc-timegrid-axis-frame::before {
  color: rgba(178, 146, 44, 1);
}
.board__calendar__box .fc-direction-ltr .fc-timegrid-slot-label-frame > *,
.board__calendar__box .fc-h-event .fc-event-title {
  color: rgba(242, 242, 242, 1);
}
.board__calendar__box .custom__calendar__full .fc-h-event,
.board__calendar__box .custom__calendar__full .fc-v-event {
  background: radial-gradient(
    160% 160% at 50.4% 58%,
    rgba(156, 131, 0, 0.4) 0%,
    rgba(227, 224, 136, 0.38) 100%
  ) !important;
  border: 0.5px solid rgba(237, 205, 106, 0.5) !important;
  box-shadow: none !important;
}
.board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:first-child > h2::before {
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: max-content;
  font-size: 14px;
}
.board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) h2,
.board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) > div .fc-toolbar-title + .fc-toolbar-title {
  color: #fff;
}
.board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:last-child > div {
  background: rgba(255, 255, 255, 0.1);
}
.board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:last-child > div .fc-button {
  box-shadow: 0px 4px 77px rgba(0, 0, 0, 0.051);
  border: 0.5px solid #edcd6a;
  background: #050e2e;
  width: 20px;
  height: 20px;
}
.board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:last-child > div .fc-button .fc-icon {
  color: #b6a238;
  font-size: 12px;
}
.board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:last-child > div .fc-toolbar-title {
  color: #fff;
  margin-left: 8px;
  margin-right: 8px;
  letter-spacing: -1px;
  font-size: 13px;
}
.board__calendar__box .fc .fc-daygrid-day.fc-day-today,
.board__calendar__box .fc .fc-timegrid-col.fc-day-today {
  background: radial-gradient(
    160% 160% at 50.4% 58%,
    rgba(255, 249, 215, 0.1136) 0%,
    rgba(255, 255, 255, 0.0592) 100%
  ) !important;
}
.board__calendar__box .custom__calendar__full .fc-v-event.fc-event-past {
  background: #273248 !important;
  border: 0px !important;
}
.board__calendar__box .custom__calendar__full .fc-v-event.fc-event-past::before {
  content: "Missed";
  font-size: 10px;
  color: rgba(255, 156, 124, 1);
  font-weight: 400;
  position: absolute;
  bottom: 5px;
  right: 5px;
  left: auto;
  top: auto;
  background: transparent;
  z-index: 1;
}
.student__onboarding__asidemenu {
  display: none;
}
.board__noevent {
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px 15px 30px;
  text-align: center;
}
.board__noevent--text {
  color: rgba(17, 24, 39, 1);
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}
.darkform__box {
  box-shadow: 0px 35px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: radial-gradient(160% 160% at 50.4% 58%, rgba(255, 249, 215, 0.1136) 0%, rgba(255, 255, 255, 0.0592) 100%);
  padding: 1rem;
  border: 0.5px solid #b6a238;
}
.darkform__profilebox {
  display: grid;
  grid-template-columns: 64px 1fr;
  grid-gap: 1rem;
  align-items: flex-start;
}
.inforbox__circle {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(167, 43, 125);
}
.inforbox__circle > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.inforbox__circle--edit {
  background: rgba(242, 242, 242, 0.7);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 1;
  visibility: hidden;
}
.inforbox__circle--edit:hover {
  background: rgba(242, 242, 242, 1);
  cursor: pointer;
}

.darkform__profilebox--pic {
  transition: all 0.2s linear;

  &:hover {
    .inforbox__circle--edit {
      visibility: visible;
    }
  }
}

.profilebox--content--authorname {
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  max-width: max-content;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}
.profilebox--content--authorclass {
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  margin-top: 2px;
}
.darkform__box__detailbar {
  padding-top: 0.875rem;
  position: relative;
  margin-top: 1.5rem;
}
.darkform__box__detailbar::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 1px;
  background: var(
    --Radial,
    radial-gradient(174.64% 160.39% at 50.4% 58%, rgba(255, 249, 215, 0.21) 0%, rgba(255, 255, 255, 0.11) 100%)
  );
}
.darkform__box__detailbar .text-white {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  line-height: 1;
}
.custom__personalbox {
  margin-top: 1.5rem;
}
.custom__personalbox .nav-link {
  color: rgba(242, 242, 242, 1);
  font-weight: 600;
  font-size: 14px;
  padding: 0px;
  padding-bottom: 5px;
  opacity: 0.7;
  border-bottom: 2px solid transparent;
  border-radius: 0px;
  text-transform: capitalize;
}
.custom__personalbox .nav-link.active {
  background: transparent;
  color: #edcd6a;
  border-color: var(--White, #f2f2f2);
  opacity: 1;
}
.custom__personalbox .nav-item + .nav-item {
  margin-left: 1rem;
}
.fieldbox--label {
  color: rgba(242, 242, 242, 0.9);
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 7px;
  display: block;
}
.personalbox--inputfield {
  background: rgba(40, 51, 73, 0.96);
  border-radius: 8px;
  border: 0.5px solid #b6a238;
  outline: none;
  padding: 0.65rem;
  padding-left: 2rem;
  color: rgba(242, 242, 242, 0.7);
  font-size: 14px;
  padding-top: 0.7rem;
}
.personalbox--icon {
  position: absolute;
  top: 14px;
  left: 10px;
}
.submitBtn--dark {
  background: radial-gradient(160% 160% at 50.4% 58%, rgba(255, 249, 215, 0.213) 0%, rgba(255, 255, 255, 0.111) 100%);
  border-radius: 6px;
  padding: 11px 12px;
  outline: none;
  box-shadow: none;
  font-weight: 600;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  color: #fff;
  width: 100%;
  justify-content: center;
  justify-content: center;
  border: 2px solid rgb(157 142 63);
  border-radius: 50px;
}
.backtxt {
  color: #fff;
  font-weight: 600;
  color: #f2f2f2;
  font-size: 16px;
  text-decoration: none;
}
.submitBtn--dark span {
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.profile--saveBtn {
  padding: 12px 18px;
  display: inline-flex;
  justify-content: baseline;
  align-items: center;
  text-decoration: none;
  box-shadow: none;
  background: transparent;
  border: 1px solid #b6a238;
  border-radius: 50px;
  line-height: 1;
}
.profile--saveBtntxt {
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 500;
  font-size: 16px;
}

.student__onboarding__asidemenu img.indicator-img {
  display: none;
}
.mobilemenu__item.active .activeIcon,
.mobilemenu__item.active .indicator--img {
  display: block;
}
.mobilemenu__item span img {
  width: 18px;
  height: 18px;
  display: block;
  margin: 0 auto;
}
.mobilemenu__item.active {
  color: #fff;
  font-weight: 600;
}
.indicator--img {
  position: absolute;
  top: 0;
  max-width: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}
.mobilemenu__item span {
  position: relative;
  z-index: 1;
}
.board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:last-child {
  display: block;
  margin-left: auto;
  /* margin-top: 25px;
    width: 100%; */
}
.board__calendar__box .fc-header-toolbar.fc-toolbar {
  flex-wrap: wrap;
  gap: 0.5rem;
}
.board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:last-child > div {
  max-width: max-content;
  margin-left: auto;
}
.board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) h2:first-child {
  opacity: 0;
}
.board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
  display: none;
}
.fc-timegrid-more-link.fc-more-link {
  background: radial-gradient(160% 160% at 50.4% 58%, rgba(255, 249, 215, 0.213) 0%, rgba(255, 255, 255, 1) 100%);
  border: 0px;
  outline: 0px;
  box-shadow: none;
  color: #fff;
  font-weight: 600;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  font-size: 12px;
  padding: 0;
  margin: 0;
  text-align: center;
  text-decoration: none;
  line-height: 20px;
}
.board__calendar__box .fc-theme-standard .fc-popover {
  background: transparent;
  border: 0;
}
.board__calendar__box .fc-theme-standard .fc-popover-header {
  background: #011f68;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -1px;
  padding: 8px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.fc .fc-popover-close {
  opacity: 1;
}
.board__calendar__box .fc .fc-more-popover .fc-popover-body .fc-v-event.fc-event-past::before {
  opacity: 0;
}
.board__calendar__box .fc .fc-more-popover .fc-popover-body {
  background: #021952 !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.board__calendar__box .fc .fc-more-popover .fc-popover-body > div + div {
  margin-top: 0.5rem;
}
.board__calendar__box .custom__calendar__full .fc-popover-body .fc-v-event.fc-event-past {
  background: radial-gradient(
    160% 160% at 50.4% 58%,
    rgba(255, 249, 215, 0.1136) 0%,
    rgba(255, 255, 255, 0.0592) 100%
  ) !important;
  border: 0.5px solid rgba(237, 205, 106, 0.5) !important;
}

@media screen and (min-width: 576px) {
  .bg--mobileset {
    background: 0;
    border: 0;
    padding: 0;
  }
}
@media screen and (min-width: 768px) {
  .onboarding__item__lists {
    grid-template-columns: repeat(4, 1fr);
  }
  .onboarding__list__item {
    grid-template-columns: auto;
    padding: 1.5rem 1rem;
    grid-gap: 0.5rem;
    min-height: 185px;
  }
  .onboarding__list__item svg {
    max-width: none;
    height: auto;
  }
  .onboarding__item__lists {
    margin-top: 5rem;
  }
  .on_litem_name {
    text-align: center;
  }
  .onboarding__item__lists {
    grid-gap: 1.5rem;
  }
  .aspirebox {
    padding: 20px 25px;
    align-items: flex-end;
    font-size: 16px;
  }
  /* .aspirebox svg {
        top: auto;
        transform: none;
    } */
  .bg--mobileset--item {
    align-items: flex-end;
  }
  .importmodal.alertmodal.newalert .modal-dialog {
    margin-left: auto;
    margin-right: auto;
    max-width: 360px;
    align-items: center;
  }
  .restpsswd .modal-content {
    filter: drop-shadow(0px 36px 26px rgba(0, 0, 0, 0.14));
    border: 1px solid #7e7233;
    margin-bottom: 0px !important;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
  .submitBtn--dark {
    border-radius: 6px;
    padding: 8px 12px;
    border: 0px;
    width: auto;
  }
  .submitBtn--dark span {
    background: transparent;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    background-clip: initial;
    text-fill-color: initial;
    color: #fff;
  }
  .board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:last-child {
    width: auto;
    margin-left: 0;
    margin-top: 0;
  }
  .board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) h2:first-child {
    opacity: 1;
  }
  .board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
    display: block;
  }
  .board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:first-child > h2::before {
    font-size: 20px;
  }
  .board__calendar__box .fc-header-toolbar.fc-toolbar {
    gap: 0rem;
  }
  .board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:last-child > div .fc-button .fc-icon {
    font-size: 18px;
  }
  .board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:last-child > div .fc-button {
    width: 32px;
    height: 32px;
  }
  .board__calendar__box .fc-header-toolbar .fc-toolbar-chunk:last-child > div .fc-toolbar-title {
    color: #fff;
    margin-left: 15px;
    margin-right: 15px;
    letter-spacing: 0px;
    font-size: 14px;
  }
}
@media screen and (min-width: 992px) {
  .student__onboarding__asidemenu {
    width: 180px;
    min-height: 460px;
    flex: 0 0 auto;
    display: flex;
    height: max-content;
    flex-direction: column;
    position: -webkit-sticky;
    position: sticky;
    top: 95px;
    background: #011342;
    border-radius: 0px 10px 10px 0px;
    padding: 1.5rem 0rem 2.5rem;
  }
  .student__onboarding__innerbox {
    margin-top: 2.5rem;
    /* padding-bottom: 0; */
  }
  .board__top--heading {
    font-size: 18px;
  }
  .overflow__x__custom {
    flex-wrap: wrap;
    max-width: 90%;
  }
  .overflow__x__custom .min-mob-300 {
    min-width: auto;
  }
  .board__noevent {
    padding-top: 25px;
  }
  .board__noevent--text {
    font-size: 24px;
  }
  .inforbox__circle {
    width: 72px;
    height: 72px;
  }
  .inforbox__circle--edit {
    width: 32px;
    height: 32px;
  }
  .darkform__profilebox {
    grid-template-columns: 72px 1fr;
  }
  .profilebox--content--authorname {
    font-size: 18px;
  }
  .profilebox--content--authorclass {
    font-size: 14px;
  }
  .custom__personalbox {
    margin-top: 2.875rem;
  }
  .mobilemenu--bar {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .student__onboarding__asidemenu.mobile-view {
    width: 100%;
    display: flex !important;
  }
  .student__onboarding__asidemenu.mobile-view,
  .aside__wrapper.mobile-view {
    background: #011342;
    box-shadow: 0px -5px 4px rgba(0, 0, 0, 0.15);
    position: fixed;
    bottom: 0;
    left: 3px;
    height: 82px;
    z-index: 9;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .student__onboarding__asidemenu.mobile-view ul {
    width: 100%;
  }
  .student__onboarding__asidemenu.mobile-view ul li a {
    display: flex;
    flex-direction: column;
  }

  .student__onboarding__asidemenu.mobile-view ul li:has(a.active) {
    color: #fff;
    font-weight: 700;
    border-left: none;
    background: url("../images/onboarding/indicator.svg") no-repeat top center;
  }

  .student__onboarding__asidemenu.mobile-view ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .student__onboarding__asidemenu.mobile-view li,
  .aside__wrapper.mobile-view li {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .student__onboarding__asidemenu ul li.active .activeIcon {
    display: block;
  }

  .onboarding__help {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .onboarding--heading {
    font-size: 2rem;
  }
  .onboarding--title {
    font-size: 1.15rem;
  }
}
@media screen and (min-width: 1400px) {
}
