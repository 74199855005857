.banner-column {
  width: 25%;
  padding-right: 20px;
  margin-top: 35px;
}

.exam-page-whole-cont .banner-column {
  margin-top: 0;
}

.banner-image {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.trigger-arrow {
  display: none;
}

.banner-trigger {
  display: none;
}

.rep-whole-cont {
  display: flex;
}

.exam-page-whole-cont {
  display: flex;
}

.exam-page-content {
  width: 75%;
}

@media (max-width: 480px) {
  .rep-whole-cont {
    display: block;
  }

  .exam-page-whole-cont {
    display: block;
  }

  .exam-page-content {
    width: 100%;
  }

  body {
    padding-bottom: 65px;
  }

  .banner-column {
    display: none;
  }

  .banner-trigger {
    display: block;
    width: calc(100% + 32px);
    padding: 0;
    margin: 0 -16px -20px -16px;
    border: none;
    background: none;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    overflow: hidden;
  }

  .banner-trigger picture {
    display: block;
    width: 100%;
  }

  .banner-image {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }

  .trigger-arrow,
  .banner-trigger-text,
  .talk-to-expert-banner-highlight-text {
    display: none;
  }
}
